import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import GeneralInfoDatasetPage from "../pages/general_info_dataset_page";
import StorageInfoExternalRepoPage from "../pages/storage_info_external_repo";
import DataAccessSharingPage from "../pages/data_access_sharing_page";
import OverviewFilesPages from "../pages/overview_files_page";
import { StepButton } from "@mui/material";
import ProjectInfoPage from "../pages/project_info_page";
import MethodologicalInfoPage from "../pages/methodological_information_page";
import DataSpecificInfoPage from "../pages/data_specific_info_page";
import RelationsPage from "../pages/relations_page";

export default function MetaNavMenu({ getMetadataValue, setMetadataValue }) {

    const [selectedMenu, setSelectedMenu] = React.useState("manData");
    const [activeStep, setActiveStep] = React.useState(0);
    const [activePageStep, setActivePageStep] = React.useState({ 'manData': 0, 'addData': 4 });

    const menuLabels = [
        {
            id: 'manData',
            label: 'VERPLICHTE DATAGEGEVENS'
        },
        {
            id: 'addData',
            label: 'AANVULLENDE METADATA'
        }
    ];

    const pages = [
        {
            label: 'PROJECT INFORMATIE',
            parent: 'manData',
            nr: 0,
            content: <ProjectInfoPage
                getMetadataValue={getMetadataValue}
                setMetadataValue={setMetadataValue}
            />

        },
        {
            label: 'ALGEMENE INFORMATIE DATASET',
            parent: 'manData',
            nr: 1,
            content: <GeneralInfoDatasetPage
                getMetadataValue={getMetadataValue}
                setMetadataValue={setMetadataValue}
            />
        },
        {
            label: 'OPSLAG INFORMATIE EXTERN REPOSITORY',
            parent: 'manData',
            nr: 2,
            content: <StorageInfoExternalRepoPage
                getMetadataValue={getMetadataValue}
                setMetadataValue={setMetadataValue}
            />
        },
        {
            label: 'TOEGANG EN DELEN DATA',
            parent: 'manData',
            nr: 3,
            content: <DataAccessSharingPage
                getMetadataValue={getMetadataValue}
                setMetadataValue={setMetadataValue}
            />
        },
        {
            label: 'OVERZICHT BESTANDEN',
            parent: 'addData',
            nr: 4,
            content: <OverviewFilesPages
                getMetadataValue={getMetadataValue}
                setMetadataValue={setMetadataValue}
            />
        },
        {
            label: 'METHODOLOGISCHE INFORMATIE',
            parent: 'addData',
            nr: 5,
            content: <MethodologicalInfoPage
                getMetadataValue={getMetadataValue}
                setMetadataValue={setMetadataValue}
            />
        },
        {
            label: 'DATASPECIFIEKE INFORMATIE',
            parent: 'addData',
            nr: 6,
            content: <DataSpecificInfoPage
                getMetadataValue={getMetadataValue}
                setMetadataValue={setMetadataValue}
            />
        },
        {
            label: 'RELATIES INFORMATIE',
            parent: 'addData',
            nr: 7,
            content: <RelationsPage
                getMetadataValue={getMetadataValue}
                setMetadataValue={setMetadataValue}
            />
        },
    ];

    const handleNext = () => {
        setStep(activeStep + 1);

    };

    const handleBack = () => {
        setStep(activeStep - 1)
    };

    const handleStep = (step) => {
        setStep(step)
    };
    const setStep = (newStep) => {
        activePageStep[selectedMenu] = newStep;
        setActivePageStep(activePageStep)
        setActiveStep(newStep)
    }

    const getContent = () => {
        return pages.find(p => p.nr === activeStep).content;
    }

    const getActivePages = () => {
        return pages.filter(p => p.parent === selectedMenu);
    }
    // const getActivePagesLabels = () => {
    //     return getActivePages().map((p, index) => (
    //         p.label
    //     ))
    // }
    const setMenu = (id) => {
        setActiveStep(activePageStep[id])
        setSelectedMenu(id)

    }

    const firstPageNr = () => {
        return getActivePages()[0].nr
    }

    const lastPageNr = () => {
        let pages = getActivePages()
        return pages[pages.length - 1].nr;
    }

    return (
        <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
            <Typography component="h1" variant="h4" align="center">
                <ButtonGroup
                    variant="contained"
                    aria-label="outlined button group"
                >
                    {
                        menuLabels.map((label, index) => (
                            <Button
                                key={label + index}
                                color={selectedMenu === label.id ? "primary" : "inherit"}
                                onClick={() => setMenu(label.id)}
                            >
                                {label.label}
                            </Button>
                        ))
                    }
                </ButtonGroup>
            </Typography>
            <Stepper
                nonLinear
                activeStep={activeStep}
                sx={{ pt: 3, pb: 5 }}
            >
                {getActivePages().map((page, index) => (
                    <Step key={page.nr} index={page.nr}>
                        <StepButton onClick={() => handleStep(page.nr)}>
                            {page.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <React.Fragment>
                {getContent()}

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {activeStep !== firstPageNr() && (
                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                            Vorige
                        </Button>
                    )}

                    {activeStep <
                        lastPageNr() ? (
                        <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Volgende
                        </Button>
                    ) : (
                        ""
                    )}
                </Box>
            </React.Fragment>
        </Paper>
    );
}
