import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const exportMetadata = (metadata) => {

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      // without pretty printing
      //JSON.stringify(Object.fromEntries(metadata))
      // with pretty printing
      
      JSON.stringify(Object.fromEntries(metadata), null, 2)
    )}`;
    console.log(jsonString);

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "metadata.json";

    link.click();
  };

export default function FullScreenDialog({metadata}) {
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
        
    <Button
        color={"primary"}
        variant="contained"
        onClick={handleClickOpen}
        >
        Opslaan
        </Button>    
    <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Export opties voor metadata
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              OPSLAAN
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText onClick={() => {exportMetadata(metadata)}} primary="HU metadata standaard" secondary="Kies deze optie voor het opslaan van je metadata op HU Research Drive zodat je het later weer kunt bewerken." />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Data verse"
              secondary="Deze externe export functie wordt gebouwd."
            />
          </ListItem>
        </List>
      </Dialog>
    </React.Fragment>
  );
}