import { Button, Typography } from "@mui/material";
import MetadataIcon from "../assets/icons/MetadataIcon";

export default function MetadataHomeButton() {
  return (
    <Button href="/" color="inherit" startIcon={<MetadataIcon />}>
      <Typography
        variant="subtitle1"
        sx={{
          fontFamily: "HUsansNormal",
        }}
        color="inherit"
      >
        MetadataGenerator
      </Typography>
    </Button>
  );
}
