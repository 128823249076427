import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MetaTextField from '../components/MetaTextField';

export default function MethodologicalInfoPage({ getMetadataValue, setMetadataValue }) {

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Methodologische informatie
            </Typography>

            <Typography variant="body2" gutterBottom>
                Voor alle afzonderlijke punten in dit onderdeel geld dat je het ofwel hier kunt vastleggen, of kunt verwijzen naar documentatie waarin deze informatie is vastgelegd.
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MetaTextField
                        id="purposeDataCollected"
                        label="Doel waarvoor de data verzameld zijn"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MetaTextField
                        id="methodDataCollected"
                        label="Methode van dataverzameling"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="infoDataProcessing"
                        label="Informatie over de verwerking van de data"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="infoMeasuringEquipment"
                        label="Informatie over het meetinstrument, eventuele kalibratie"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="usedQualityAssuranceProcedures"
                        label="Gebruikte procedures voor kwaliteitsbewaking"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="infoInterpretationDataset"
                        label="Informatie over de afbakening van de dataset, benodigde informatie voor correcte interpretatie van de dataset"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="personInvolvedCreationDataset"
                        label="Personen die betrokken waren bij de creatie en de verwerking van de dataset"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
