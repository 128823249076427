import { Box, Button, Container, Grid } from "@mui/material";
import Copyright from "./Copyright";
import HULogo from "../assets/logos/HULogo";
import FooterDivider from "./FooterDivider";

export default function Footer() {
  return (
    <Box
      component={"footer"}
      sx={{ backgroundColor: "footer.background", py: 2, mt: "auto" }}
    >
      <Container>
        <FooterDivider>Hier komt alles samen</FooterDivider>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button href="https://www.hu.nl/">
              <HULogo color1="#bbbbbb" color2="#fff" />
            </Button>
          </Grid>
          <Grid item>
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
