import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MetaTextField from '../components/MetaTextField';

export default function DataSpecificInfoPage({ getMetadataValue, setMetadataValue }) {

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Dataspecifieke informatie
            </Typography>

            <Typography variant="body2" gutterBottom>
                Voor alle afzonderlijke punten in dit onderdeel geld dat je het ofwel hier kunt vastleggen, of kunt verwijzen naar documentatie waarin deze informatie is vastgelegd.
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MetaTextField
                        id="allNamesDefinitionsColumnsRows"
                        label="Volledige namen en definities voor kolommen en rijen"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MetaTextField
                        id="usedExplanationAbbreviations"
                        label="Verklaring van gebruikte afkortingen"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="usedUnitsOfMeasurments"
                        label="Gebruikte meeteenheden"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="missingDataSymbols"
                        label="Symbolen voor ontbrekende data"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
