import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MetaTextField from '../components/MetaTextField';

export default function OverviewFilesPage({ getMetadataValue, setMetadataValue }) {

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Overzicht bestanden
            </Typography>

            <Typography variant="body2" fontWeight='bold' sx={{ textDecoration: 'underline' }} gutterBottom>
                Dit onderdeel is niet verplicht voor standaard archivering, maar het omvat metadata die een gedetailleerd beeld geven van de dataset.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Gegevens die hierin vastgelegd worden, vergroten de herbruikbaarheid van een dataset. Alle kennis die nodig is om de dataset opnieuw te kunnen gebruiken en/of te kunnen interpreteren leg je hierin vast. Wanneer je een dataset in een repository gaat delen, moet je veelal wel bepaalde onderdelen uit onderstaande vragen invullen, aanvullend op de algemene metadata uit onderdeel A.
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MetaTextField
                        id="metadataFileCount"
                        label="Aantal bestanden beschreven in dit Metadatabestand"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MetaTextField
                        id="listFilenamesDescCreateDate"
                        label="Lijst met bestandsnamen, beschrijving, aanmaakdatum bestand"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="usedFileFormats"
                        label="Gebruikte bestandsformaten"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="usedDataGeneratorSoftware"
                        label="Gebruikte software om de data te genereren"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="neededSoftwareToOpenFiles"
                        label="Benodigde software om de bestanden te kunnen openen"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="relationBetweenFiles"
                        label="Relatie tussen de bestanden"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="versionInfoDataset"
                        label="Informatie over versies van de dataset en redenen voor updates"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="usedFileNamingConvention"
                        label="Gebruikte conventie voor naamgeving bestanden"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
