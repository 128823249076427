import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import GitHubIcon from "@mui/icons-material/GitHub";
import Grid from "@mui/material/Grid";
import { isEmpty } from "./utils";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";
import FullScreenDialog from "./components/saveDialog";
import MetaNavMenu from "./components/MetaNavMenu";
import Footer from "./components/Footer";
import HULogo from "./assets/logos/HULogo";
import MetadataHomeButton from "./components/MetadataHomeButton";

export default function MetadataGenerator() {
  const fileUploadInput = React.createRef();

  const [metadata, setMetadata] = React.useState(new FormData());
  const [metaNavMenuId, setMetaNavMenuId] = React.useState(0);

  const setMetadataValue = (event) => {
    metadata.set(event.target.name, event.target.value);
  };

  const getMetadataValue = (fieldName) => {
    return metadata.get(fieldName) ?? "";
  };

  // Check if we have an empty or unmodified metata form
  // [TODO] use the state to auto update the ui
  const isFresh = () => {
    for (const pair of metadata.entries()) {
      if (!isEmpty(pair[1])) {
        return false;
      }
    }

    return true;
  };

  // Read and process the uploaded file content
  const handleFileUpload = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = reader.result;
      //console.log(fileContent);
      //console.log(JSON.parse(fileContent));
      // empty value so upload of the same file is possible
      event.target.value = null;

      const newMetadata = new FormData();
      const fileJson = JSON.parse(fileContent);
      Object.keys(fileJson).forEach((fieldName) => {
        newMetadata.set(fieldName, fileJson[fieldName]);
      });
      setMetadata(newMetadata);

      setMetaNavMenuId(0);
    };

    if (event.target.files.length > 0) {
      // [hacky] By changing the id of the MetaNavMenu a reload of the component is triggered
      setMetaNavMenuId(-1);
      // Load the given file into the text reader
      reader.readAsText(event.target.files[0]);
    }
  };

  const handleOpen = (e) => {
    fileUploadInput.current.click();
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: "#f6f6f6", // original color grey: #f2f2f2
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <AppBar
          position="static"
          color="inherit"
          elevation={0}
          sx={{
            position: "relative",
            borderBottom: (t) => `2px solid ${t.palette.divider}`,
            padding: "1.5px 0",
          }}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Button href="https://www.hu.nl/">
                  <HULogo />
                </Button>
                <MetadataHomeButton />
              </Grid>
              <Grid item>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="myfile"
                  ref={fileUploadInput}
                  onChange={handleFileUpload}
                />
                <Button
                  variant="contained"
                  onClick={handleOpen}
                  sx={{ mx: 1.5 }}
                >
                  Openen
                </Button>

                <FullScreenDialog metadata={metadata}></FullScreenDialog>

                <Button
                  style={{ textTransform: "none" }}
                  variant="text"
                  sx={{ mx: 1.5 }}
                  startIcon={<GitHubIcon />}
                  href="https://github.com/uashogeschoolutrecht/metadata-generator"
                  color="inherit"
                >
                  GitHub
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
          <Box component="form" sx={{ mt: 1 }}>
            <MetaNavMenu
              key={metaNavMenuId}
              getMetadataValue={getMetadataValue}
              setMetadataValue={setMetadataValue}
            />
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
