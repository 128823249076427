export const palette = {
  HUBlauw: "#00a1e1",
  HUSignaalblauw: "#0088c8",
  HUDonkerblauw: "#006eae",
  HUBabyblauw: "#d9f1fb",
  HURood: "#e6302b",
  HUDonkerrood: "#cd1712",
  HUWit: "#fff",
  HUGrijs1: "#f6f6f6",
  HUGrijs2: "#dddddd",
  HUGrijs3: "#bbbbbb",
  HUGrijs4: "#757575",
  HUGrijs5: "#333",
  HUZwart: "#000",
  Instagram: "#f4306d",
  Whatsapp: "#57bb63",
  footerBackground: "#333",
};
