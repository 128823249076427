import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import MetaTextField from '../components/MetaTextField';
import Paper from '@mui/material/Paper';
import InfoTooltip from '../components/InfoTooltip';

export default function StorageInfoExternalRepoPage({ getMetadataValue, setMetadataValue }) {

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Opslag informatie externe repository
            </Typography>
            <Typography variant="body2" gutterBottom>
                We maken onderscheid tussen de verplichte standaard archivering en het delen van een dataset in een online repository voor hergebruik door andere onderzoekers. <br />
                <br />
                De standaard archivering is verplicht binnen onderzoek. Archivering van een HU-project zal bij de HU zelf plaatsvinden (archiefomgeving HU), ten minste voor een periode van 10 jaar. Het delen van data via een online repository (zoals bijvoorbeeld bij DANS EASY) begint binnen de wetenschap steeds belangrijker te worden in het kader van open science. Dit is echter niet verplicht en zeker ook niet voor alle datasets mogelijk. Wanneer hier wel sprake van is, is het belangrijk om de informatie over waar/ met wie de data is gedeeld ook vast te leggen in dit document. <br />
                <br />
            </Typography>
            <Typography variant="body2" fontWeight='bold' gutterBottom>
                De onderdelen ‘opslag informatie’ en ‘toegang en delen van data’ zijn van toepassing indien de dataset via een online repository, met een andere instelling of met derden gedeeld gaat worden/ gedeeld is.
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={11}>
                    <MetaTextField
                        id="dataStorageLocation"
                        label="Waar zijn de data opgeslagen?"
                        infoLabel="Bijvoorbeeld bij een andere instelling, of de online repository DANS EASY."
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Opslaglocatie"
                        info="Bijvoorbeeld bij een andere instelling, of de online repository DANS EASY."
                        supportURLS={[]}
                    />
                </Grid>
            </Grid>

            <br />
            <Typography variant="caption" gutterBottom>
                Informatie over de locatie(s) waar de dataset beschikbaar is met link.
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="repoName"
                            label="Naam instelling/repository"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="repoUrl"
                            label="Link (indien van toepassing)"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <MetaTextField
                            id="repoPID"
                            label="Persistent Identifier (PID)"
                            infoLabel="Bijvoorbeeld een DOI."
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    <InfoTooltip
                        title="Persistent Identifier (PID)"
                        info="Bijvoorbeeld een DOI."
                        supportURLS={[]}
                    />
                </Grid>
                </Grid>
            </Paper>

        </React.Fragment>
    );
}
