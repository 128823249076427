import TextField from "@mui/material/TextField";

/// A shorthand for making eaxy textfield for the metadata form
export default function MetaTextField({ id, label, fullWidth, get, set }) {
  // autofill is nog niet van toepassing, meer info zie: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
  //const autofillId = id.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

  return (
    <TextField
      id={id}
      name={id}
      label={label}
      fullWidth={fullWidth ?? true}
      //autoComplete={autofillId}
      variant="outlined"
      defaultValue={get(id)}
      onChange={set}
    />
  );
}
