export default function MetadataIcon(height = "1em", color = "#000000") {
  return (
    <svg width="1em" height={height} viewBox="0 0 512.000000 512.000000">
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M1875 5114 c-389 -30 -650 -76 -918 -164 -326 -108 -551 -259 -633
            -425 l-29 -60 0 -450 0 -450 28 -58 c119 -241 539 -446 1101 -537 166 -27 383
            -50 478 -50 l77 0 46 91 c203 404 576 691 1021 784 263 55 522 38 779 -52 55
            -19 110 -37 123 -40 l24 -6 -4 374 c-4 409 -5 412 -67 503 -177 257 -649 445
            -1307 522 -122 14 -611 26 -719 18z m665 -200 c678 -61 1240 -304 1240 -538 0
            -86 -118 -195 -315 -291 -341 -166 -778 -247 -1330 -248 -330 0 -513 16 -787
            69 -467 90 -817 269 -870 445 -39 131 160 295 501 413 430 150 982 203 1561
            150z"
        />
        <path
          d="M3229 3610 c-502 -48 -938 -398 -1092 -875 -49 -151 -61 -228 -60
            -400 0 -128 5 -180 23 -259 58 -261 161 -449 349 -637 187 -188 396 -301 661
            -356 104 -22 350 -24 460 -4 169 30 352 105 500 204 101 67 275 243 340 342
            166 255 240 546 211 831 -55 545 -433 986 -959 1119 -80 20 -306 49 -347 43
            -5 0 -44 -4 -86 -8z m336 -229 c204 -42 375 -134 525 -281 216 -212 322 -459
            323 -755 0 -183 -32 -324 -110 -480 -58 -115 -114 -193 -199 -277 -158 -155
            -306 -238 -514 -288 -140 -35 -356 -33 -491 2 -253 68 -456 203 -607 405 -232
            308 -272 733 -102 1090 58 122 112 198 219 303 147 145 320 238 521 281 118
            25 316 25 435 0z"
        />
        <path
          d="M291 2844 l-1 -371 35 -70 c151 -301 733 -529 1485 -582 74 -6 136
            -8 139 -5 2 2 -8 44 -23 92 -52 171 -77 406 -59 564 l9 77 -50 5 c-178 17
            -429 50 -531 71 -518 104 -896 312 -985 540 l-19 50 0 -371z"
        />
        <path
          d="M293 1735 l2 -380 29 -60 c67 -137 259 -279 512 -378 250 -98 562
            -165 919 -197 166 -15 583 -15 745 0 244 22 523 71 679 119 l56 17 -65 8
            c-371 42 -738 245 -981 542 l-25 31 -184 6 c-516 19 -966 115 -1282 272 -128
            64 -184 101 -262 174 -72 69 -93 98 -123 170 l-23 56 3 -380z"
        />
        <path
          d="M4036 1151 c-148 -86 -269 -160 -268 -164 0 -8 399 -705 412 -720 5
            -6 527 287 547 307 6 5 -392 702 -411 722 -8 8 -83 -30 -280 -145z"
        />
        <path
          d="M4512 316 c-145 -84 -264 -156 -264 -160 -1 -22 76 -95 128 -121 77
            -38 193 -42 265 -9 111 52 179 149 187 270 5 76 -19 175 -41 173 -7 0 -131
            -69 -275 -153z"
        />
      </g>
    </svg>
  );
}
