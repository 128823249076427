import { Divider, Typography } from "@mui/material";

export default function FooterDivider({ children }) {
  return (
    <Divider
      role="presentation"
      sx={{
        "&::before, &::after": {
          borderColor: "footer.text",
        },
      }}
    >
      <Typography
        fontFamily="HusansNormal"
        fontSize="1.5em"
        color="footer.text"
      >
        {children}
      </Typography>
    </Divider>
  );
}
