import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MetaTextField from '../components/MetaTextField';
import { Paper } from '@mui/material';

export default function RelationsPage({ getMetadataValue, setMetadataValue }) {

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Relaties
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MetaTextField
                        id="datasetPublications"
                        label="Publicaties gebaseerd op deze dataset"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
            </Grid>

            <Typography variant="caption" gutterBottom>
                Deze dataset is afgeleid van andere dataset(s), namelijk
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="derivedDataset1Title"
                            label="Titel"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="derivedDataset1PID"
                            label="Persistent identifier (bv. Doi)"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MetaTextField
                        id="datasetRelations"
                        label="Deze dataset is gerelateerd aan… (documenten, andere dataset)"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="datasetReferences"
                        label="Referenties van publicaties die gebruikt zijn voor de creatie van de dataset"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
