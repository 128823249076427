import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MetaTextField from '../components/MetaTextField';
import InfoTooltip from '../components/InfoTooltip';

export default function DataAccessSharingPage({ getMetadataValue, setMetadataValue }) {

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Toegang en delen data
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={11}>
                    <MetaTextField
                        id="recommendedCitationDataset"
                        label="Aanbevolen citatie voor de dataset"
                        infoLabel="Vergelijkbaar met een citatie voor een publicatie, bijvoorbeeld: Auteur(s) (Jaar). Titel van de dataset (versienummer) [Dataset]. Publisher Name (repository). DOI adres."
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Aanbevolen citatie"
                        info="Vergelijkbaar met een citatie voor een publicatie, bijvoorbeeld: Auteur(s) (Jaar). Titel van de dataset (versienummer) [Dataset]. Publisher Name (repository). DOI adres."
                        supportURLS={[]}
                    />
                </Grid>

                <Grid item xs={11}>
                    <MetaTextField
                        id="licenseAndUsage"
                        label="Licentie informatie, beperkingen gebruik"
                        infoLabel="Wanneer je data gaat delen kies je een licentie. Deze licentie bepaald onder welke voorwaarden de dataset gebruikt mag worden door derden. Net zoals bij een (wetenschappelijke) publicatie betreft dit (vaak) een creative commons licentie. Je kiest als onderzoeker zelf de meest geschikte licentie voor je data en/of publicatie. Soms zijn hier eisen aan verbonden vanuit een overeenkomst met partners of vanuit een subsidieverstrekker. Voor meer informatie over verschillende soorten licenties ga naar: https://creativecommons.nl/uitleg/ of https://creativecommons.org/licenses/"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Licentie"
                        info="Wanneer je data gaat delen kies je een licentie. Deze licentie bepaald onder welke voorwaarden de dataset gebruikt mag worden door derden. Net zoals bij een (wetenschappelijke) publicatie betreft dit (vaak) een creative commons licentie. Je kiest als onderzoeker zelf de meest geschikte licentie voor je data en/of publicatie. Soms zijn hier eisen aan verbonden vanuit een overeenkomst met partners of vanuit een subsidieverstrekker"
                        supportURLS={['https://creativecommons.nl/uitleg/','https://creativecommons.org/licenses/']}
                    />
                </Grid>
                <Grid item xs={11}>
                    <MetaTextField
                        id="embargoDuration"
                        label="Embargo periode (indien van toepassing)"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={11}>
                    <MetaTextField
                        id="confidentialInformation"
                        label="Vertrouwelijke informatie"
                        infoLabel="Beschrijf hier welke vertrouwelijke informatie in de dataset is opgenomen, indien van toepassing."
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Vertrouwelijke informatie"
                        info="Beschrijf hier welke vertrouwelijke informatie in de dataset is opgenomen, indien van toepassing."
                        supportURLS={[]}
                    />
                </Grid>
                <Grid item xs={11}>
                    <MetaTextField
                        id="permissionDatasetReuse"
                        label="Toestemming voor (deel) dataset hergebruik"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="hergebruik"
                        info="Hier beschrijf je óf er toestemming is gevraagd aan deelnemers (indien van toepassing), en welke soort toestemming(en) dit betreft. Geef een korte samenvatting en verwijs naar een template (leeg) toestemmingsformulier welke binnen dit onderzoek gebruikt is (zodat de exacte formulering van toestemming(en) altijd terug te vinden is). 
                        Bijvoorbeeld: 
                        Er is toestemming van alle deelnemers/ een deel van de deelnemers voor: 1)Hergebruik data binnen zelfde onderzoek/ toekomstig nieuw onderzoek. en 2)
                        Delen (geanonimiseerde/gepseudonimiseerde) data. 
                        Zie document [naam template toestemmingsformulier versie].
                        "
                        supportURLS={[]}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
