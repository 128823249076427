import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import MetaTextField from '../components/MetaTextField';
import Paper from '@mui/material/Paper';
import InfoTooltip from '../components/InfoTooltip';

export default function ProjectInfoPage({ getMetadataValue, setMetadataValue }) {

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Project informatie
            </Typography>

            <Typography variant="body2" gutterBottom>
                Het documenteren van metadata is van belang voor het waarborgen van o.a. de duurzaamheid, herbruikbaarheid en reproduceerbaarheid van data. <br />
                <br />
                Metadata is informatie over de verzamelde dataset, zoals de context of discipline waarbinnen de data verzameld is, locatie(s) of periode waarin de gegevens verzameld zijn en de dataset versie. Tevens valt hier informatie over de inhoud van de dataset zelf onder, zoals welke variabelen er in de dataset zitten, en de definiëring van die variabelen. Ten slotte is het belangrijk dat praktische informatie duidelijk vastgelegd is, zoals eigenaarschap en contactgegevens van de betrokken (hoofd-)onderzoeker(s).<br />
                <br />
            </Typography>
            <Typography variant="body2" fontWeight='bold' gutterBottom>
                In dit document leg je de metadata van je dataset zo goed mogelijk vast. Dit is een levend document. Dat wil zeggen dat je bij aanvang van een project invult wat je weet, en dit gedurende de looptijd van een project bijwerkt.
            </Typography>
            <Box sx={{ my: 1 }}>
                <Divider light />
            </Box>
            <Typography variant="body2" gutterBottom>
                Alle gegevens die in dit onderdeel gedocumenteerd worden zijn belangrijk om een duidelijk beeld te krijgen van de inhoud van de dataset en de (her-) bruikbaarheid ervan. <br />
                <br />
            </Typography>

            <Typography variant="caption" gutterBottom>
                Project naam
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="projectNameDutch"
                            label="Nederlandstalig"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="projectNameEnglish"
                            label="Engelstalig"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Grid container spacing={3}>
                <Grid item xs={11}>
                    <MetaTextField
                        id="abbreviationProjectName"
                        label="Afkorting/acroniem project"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Project naam"
                        info="Geef hier de algemene projectnaam op van het project."
                        supportURLS={['https://datascience.hu.nl', "https://onderzoek.hu.nl"]}
                    />
                </Grid>

                <Grid item xs={12}>
                    <MetaTextField
                        id="shortProjectDesc"
                        label="Korte project samenvatting"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="projectDuration"
                        label="Looptijd project"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
            </Grid>

            <br />
            <Typography variant="caption" gutterBottom>
                Project financier(s). Indien er meerdere financiers zijn, graag voor alle financiers onderstaande invullen.
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="financierName"
                            label="Naam financier"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="subsidyType"
                            label="Type subsidie"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="subsidyNumber"
                            label="Subsidie nummer"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <br />
            <Typography variant="caption" gutterBottom>
                Hoofdonderzoeker (HU)
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="leadResearcherName"
                            label="Naam"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="leadResearcherContactDetails"
                            label="Contactgegevens (email, telefoonnummer)"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="leadResearcherResearchGroup"
                            label="Lectoraat"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <MetaTextField
                            id="leadResearcherORCID"
                            label="ORCID"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    <InfoTooltip
                        title="ORCID"
                        info="ORCID is een internationale digitale persistent identifier voor een onderzoeker, waarmee een onderzoeker identificeerbaar en te onderscheiden is van andere onderzoekers. Het ORCID ID kan gekoppeld worden aan alle professionele informatie (affiliaties, subsidies, publicaties etc.). "
                        supportURLS={['https://orcid.org/']}
                    />
                    </Grid>
                    
                    <Grid item xs={11}>
                        <MetaTextField
                            id="leadResearcherDAI"
                            label="DAI"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    <InfoTooltip
                        title="DAI"
                        info="DAI staat voor Digital Author Identifier, en is een uniek (nationaal) nummer toegekend aan iedere auteur met een positie aan een Nederlandse universiteit of onderzoeksinstituut."
                        supportURLS={['https://wiki.surfnet.nl/display/standards/DAI']}
                    />
                    </Grid>
                </Grid>
            </Paper>

            <br />
            <Typography variant="caption" gutterBottom>
                Andere leden van het onderzoeksteam
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher1Name"
                            label="Naam"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher1Role"
                            label="Rol"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher1ORCID"
                            label="ORCID"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher1DAI"
                            label="DAI"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher1EducationalInstitution"
                            label="(Onderwijs)Instelling"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher2Name"
                            label="Naam"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher2Role"
                            label="Rol"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher2ORCID"
                            label="ORCID"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher2DAI"
                            label="DAI"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="otherResearcher2EducationalInstitution"
                            label="(Onderwijs)Instelling"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>


                </Grid>
            </Paper>

            <br />
            <Typography variant="caption" gutterBottom>
                Betrokken onderzoekondersteuners
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="researchAssistant1Name"
                            label="Naam"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="researchAssistant1Role"
                            label="Rol"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Grid container spacing={3}>
                <Grid item xs={11}>
                    <MetaTextField
                        id="disciplineStudyDataCollected"
                        label="Discipline van de studie waarvoor de data is verzameld"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Discipline"
                        info="Bijvoorbeeld psychology, communication, education, etc."
                        supportURLS={[]}
                    />
                </Grid>
            </Grid>

            <br />
            <Typography variant="caption" gutterBottom>
                Trefwoorden project (door auteur gedefinieerd)
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="projectTagsDutch"
                            label="Nederlandstalig trefwoorden"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="projectTagsEnglish"
                            label="Engelstalig trefwoorden"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                </Grid>
            </Paper>


        </React.Fragment>
    );
}
