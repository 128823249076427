import { createTheme } from "@mui/material/styles";
import { palette } from "./Palette";

// The HU Design can be found at https://hogeschoolutrecht.design and https://hu-stijlgids.netlify.app/

export const theme = createTheme({
  palette: {
    primary: {
      main: palette.HUBlauw,
      light: palette.HUSignaalblauw,
      dark: palette.HUDonkerblauw,
      contrastText: palette.HUWit,
    },
    secondary: {
      main: palette.HURood,
      HUDonkerrood: palette.HUDonkerrood,
      contrastText: palette.HUWit,
    },
    footer: {
      background: palette.footerBackground,
      text: palette.HUWit,
    },
  },
  typography: {
    fontFamily: ["Avenir LT", "Avenir Heavy", "Avenir Black"].join(", "),
  },
});
