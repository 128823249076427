import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MetaTextField from '../components/MetaTextField';
import Paper from '@mui/material/Paper';
import InfoTooltip from '../components/InfoTooltip';

export default function GeneralInfoDatasetPage({ getMetadataValue, setMetadataValue }) {
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Algemene informatie dataset
            </Typography>

            <Typography variant="caption" gutterBottom>
                Naam van de dataset
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="nameDataset1Dutch"
                            label="Nederlandstalig"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="nameDataset1English"
                            label="Engelstalig"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Grid container spacing={3}>
                <Grid item xs={11}>
                    <MetaTextField
                        id="ownerDataset1"
                        label="Eigenaarschap dataset"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Eigenaarschap dataset"
                        info="Standaard is de HU eigenaar van data die bij/vanuit de HU verzameld wordt. Indien dit afwijkend is, en dit ook vastgelegd is in een overeenkomst, dit hier vermelden."
                        supportURLS={[]}
                    />
                </Grid>
                
                <Grid item xs={11}>
                    <MetaTextField
                        id="dateLastChangeMetadataFile"
                        label="Datum laatste aanpassing Metadatabestand"
                        infoLabel="JJJJ-MM-DD"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="invulmethode"
                        info="JJJJ-MM-DD"
                        supportURLS={[]}
                    />
                </Grid>
            </Grid>

            <Grid item xs={11}>
                <Typography variant="caption" gutterBottom>
                    Trefwoorden dataset (door auteur gedefinieerd):
                </Typography>
                <Paper variant="outlined" style={{ padding: 18, margin: "0px 0px 18px 0px" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={11}>
                            <MetaTextField
                                id="datasetDutchTags"
                                label="Nederlandstalig trefwoorden"
                                get={getMetadataValue}
                                set={setMetadataValue}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <InfoTooltip
                                title="Trefwoorden"
                                info="Indien er gecontroleerde vocabulaire bestaat binnen het veld/ de discipline is het toevoegen van gecontroleerde vocabulaire gewenst. Gecontroleerd vocabulaire bestaat uit voorkeurstermen die gehanteerd worden om de vindbaarheid van data(sets) te vergroten. Door het gebruik van gecontroleerd vocabulaire kunnen datasets consistent teruggevonden worden. Om geschikte trefwoorden te vinden kun je bijvoorbeeld termen zoeken in een aan het discipline gerelateerde thesaurus."
                                supportURLS={[]}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <MetaTextField
                                id="datasetDutchTagsEnglish"
                                label="Engelstalig trefwoorden"
                                get={getMetadataValue}
                                set={setMetadataValue}
                            />
                        </Grid>
                    </Grid>               
                </Paper>
            </Grid>
            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MetaTextField
                        id="shortDatasetDescription"
                        label="Korte beschrijving van de dataset"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="languageDataset"
                        label="Taal gebruikt in de dataset"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={11}>
                    <MetaTextField
                        id="storageDurationArchivingDataset"
                        label="Opslagperiode voor archivering dataset"
                        infoLabel="De standaard opslagperiode voor archivering van datasets is doorgaans 10 jaar. Indien de opslagperiode voor deze dataset afwijkend is van standaard, toelichten waarom."
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Opslagperiode"
                        info="De standaard opslagperiode voor archivering van datasets is doorgaans 10 jaar. Indien de opslagperiode voor deze dataset afwijkend is van standaard, toelichten waarom."
                        supportURLS={[]}
                    />
                </Grid>

                <Grid item xs={11}>
                    <MetaTextField
                        id="dataStoredDuringProjectOrStandardArchiving"
                        label="Waar zijn de data opgeslagen tijdens de uitvoer van het project of voor de standaard archivering?"
                        infoLabel="Bijvoorbeeld HU Research Drive. Antwoord aanpassen afhankelijk van of het project nog loopt of gearchiveerd is."
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Opslaglocatie"
                        info="Bijvoorbeeld HU Research Drive. Antwoord aanpassen afhankelijk van of het project nog loopt of gearchiveerd is."
                        supportURLS={[]}
                    />
                </Grid>
                <Grid item xs={11}>
                    <MetaTextField
                        id="versionNumberDataset"
                        label="Welk versienummer van de dataset betreft het?"
                        infoLabel="Dit is pas in te vullen bij afronding project en betreft de definitieve versie van de dataset."
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                    
                </Grid>
                <Grid item xs={1}>
                    <InfoTooltip
                        title="Versienummer"
                        info="Dit is pas in te vullen bij afronding project en betreft de definitieve versie van de dataset."
                        supportURLS={[]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MetaTextField
                        id="dateFinalVersion"
                        label="Wat is de datum van deze definitieve versie? "
                        infoLabel="JJJJ-MM-DD"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
            </Grid>

            <Typography variant="caption" gutterBottom>
                Periode dataverzameling
            </Typography>
            <Paper variant="outlined" style={{ padding: 8, margin: "0px 0px 8px 0px" }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="beginDateDataCollection"
                            label="Begindatum"
                            infoLabel="JJJJ-MM-DD"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MetaTextField
                            id="endDateDataCollection"
                            label="Einddatum"
                            infoLabel="JJJJ-MM-DD"
                            get={getMetadataValue}
                            set={setMetadataValue}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MetaTextField
                        id="locationDataCollection"
                        label="Locatie(s) van dataverzameling (land/ steden)"
                        get={getMetadataValue}
                        set={setMetadataValue}
                    />
                </Grid>
            </Grid>

        </React.Fragment >
    );
}
