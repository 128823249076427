import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

/**
 * The InfoTooltip component renders a 
 * button with an info icon, wrapped in an HtmlTooltip. The tooltip's 
 * content is composed of a title, info, and a list of external URLs 
 * passed in as the supportURLS prop. Each URL is rendered as a list 
 * item with a link to the URL, with the target set to _blank and rel 
 * set to "noreferrer" to open the URL in a new tab and prevent the 
 * referer header from being sent.
 * @param {string} title - the title of the tooltip.
 * @param {string} info - the information displayed in the tooltip
 * @param {string[]} supportURLS - an array of external URLs to display in the tooltip.
 */
export default function InfoTooltip({ title, info, supportURLS}) {
    // checks for empty support URLs and removes references. 
    if (supportURLS.length !== 0){
      var references = <b>{'Externe bronnen:'}</b>
      var externalURLList = supportURLS.map(function(URL){
        return(<li key={URL.toString()}>
            <a href={URL} target="_blank" rel="noreferrer">{URL}</a>
          </li>)
      })
    }

    return (
      <div>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="inherit">{ title }</Typography>
              { info }
              <div>
              {references}
              {externalURLList}
              </div>
            </React.Fragment>
          }
        >
        <IconButton>
          <InfoIcon />
        </IconButton>
        </HtmlTooltip>
      </div>
    );
  }